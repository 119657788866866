<template>
  <header>
  <section>
      <a href="tel:19992712115" target="_blank" rel="noopener noreferrer"><span><svg
            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone"
            viewBox="0 0 16 16">
            <path
              d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
          </svg><span><span>&nbsp;<strong>(19) 99271-2115</strong></span></span></span></a><a href="https://api.whatsapp.com/send?phone=5519992712115&amp;text=Olá%20Ney%20Guincho,%20Gostaria%20de%20solicitar%20um%20orçamento%20de%20Guincho." target="_blank"
        rel="noopener noreferrer"><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-whatsapp" viewBox="0 0 16 16">
            <path
              d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
          </svg><span><span>&nbsp;<strong>(19) 99271-2115</strong></span></span></span></a>
        </section>
  <section> <nav class="navbar">
      <div class="logo">
          <img src="@/assets/logo.webp" alt="logo" />
      </div>
      <div class="push-left">
        <button id="menu-toggler" v-on:click="menu" data-class="menu-active" class="hamburger">
          <span class="hamburger-line hamburger-line-top"></span>
          <span class="hamburger-line hamburger-line-middle"></span>
          <span class="hamburger-line hamburger-line-bottom"></span>
        </button>

        <!--  Menu compatible with wp_nav_menu  -->
        <ul id="primary-menu" class="menu nav-menu">
          <li class="menu-item current-menu-item">
            <router-link v-on:click="menu" class="nav__link"
              to="/">Home</router-link></li>

          <li class="menu-item dropdown"> <router-link v-on:click="menu" class="nav__link"
              to="/servicos">Serviços</router-link>
          </li>
          <li class="menu-item dropdown"> <router-link v-on:click="menu" class="nav__link"
              to="/galeria">Galeria</router-link>
          </li>

          <li class="menu-item ">
            <router-link v-on:click="menu" class="nav__link" to="/contato">Contato</router-link>

          </li>
        </ul>
      </div>
    </nav>
  </section>
</header>
</template>
<script>
export default {
  name: 'Header',
  methods: {
    menu () {
      document.body.classList.toggle('menu-active')
    }
  }
}
</script>
<style lang="scss" scoped>
/* Extra */
$primary: #fff;
$secondary: #ccc;
$ternary: #272727;
$dark: #444444;
header{
  display: grid;
  grid-template-rows: repeat(2,auto);
}
* {
  section {
    height: auto!important;
    margin: 0!important;
    &:nth-child(1) {
      height: 100%!important;
      background: red;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content:flex-end;
      a {
        color: #fff
      }
    }

    &:nth-child(2) {
      background: green;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      a {
        color: red
      }
    }

    margin-bottom:2rem !important;
  }
}

a {
  margin: 1rem !important;
}
span, svg{
  vertical-align: middle;
}
.logo img{
  max-width: 150px !important;
  float: left;
  margin-left: 1rem !important;
}

.navbar {
  align-items:center;
  justify-content: space-between;
  background: $primary;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  font-family: sans-serif;
  padding: 10px 60px;
  min-width: 100%!important;

  @media only screen and (max-width: 600px) {
    padding: 0;
  }

}

/* .push-left {
  margin-left: auto;
} */

/* Menu */
.hamburger {
  background: transparent;
  float: right;
  cursor: pointer;
  position: relative;
  float: right!important;
  height: 30px;
  width: 30px;
  z-index: 1000;
  margin-right: 1rem !important;

  /* @media screen and (max-width: 768px) {
    display: inline-block;
  } */

  &-line {
    background: $ternary;
    height: 3px;
    position: absolute;
    left: 0;
    transition: all .2s ease-out;
    width: 100%;

    .hamburger:hover & {
      background: #777;
    }

    &-top {
      top: 3px;
    }

    .menu-active &-top {
      top: 50%;
      transform: rotate(45deg) translatey(-50%);
    }

    &-middle {
      top: 50%;
      transform: translatey(-50%);
    }

    .menu-active &-middle {
      left: 50%;
      opacity: 0;
      width: 0;
    }

    &-bottom {
      bottom: 3px;
    }

    .menu-active &-bottom {
      bottom: 50%;
      transform: rotate(-45deg) translatey(50%);
    }
  }
}

.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: all .25s ease-in;

  @media screen and (max-width: 768px) {
    background: $primary;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translatey(-100%);
    text-align: center;

    .menu-active & {
      transform: translatey(0%);
      opacity: 1;
    }
  }

  .menu-item a {
    color: $dark;
    display: block;
    line-height: 30px;
    margin: 0px 10px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      color: lighten($dark, 20);
      text-decoration: underline;
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
      margin: 8px;
    }
  }

}

.sub-nav {
  border: 1px solid #ccc;
  display: none;
  position: absolute;
  background-color: $primary;
  padding: 5px 5px;
  list-style: none;
  width: 230px;

  @media screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    display: none;
    background-color: rgba(0, 0, 0, 0.20);
    box-sizing: border-box;
  }
}

.nav__link {
  &:hover+.sub-nav {
    display: block;

  }
}

.sub-nav {
  &:hover {
    display: block;
  }
}</style>
