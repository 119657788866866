<template>
  <div>
    <div>
      <div>
        <h1>
          <span><span>Guincho 24HS</span></span>
        </h1>
      </div>
      <div>
        <h6><span><span>Remoções
              de Carros, Motos e Utilitários&nbsp;</span></span></h6>
      </div>
      <div>
        <h6><span><span>Indaiatuba e
              Região&nbsp;</span></span></h6>
      </div>
    </div>
    <div>
      <img src='@/assets/serv.jpeg'>
    </div>
    <div class='fbox' style='background:#ff0000'>
      <div>
        <h2 style='font-size:73px;'><span style='font-size:73px;'><span>Preço e Qualidade
              Imbatível</span></span></h2>
      </div>
      <div>
        <h2 style='font-size:19px;'>Guincho 24 Horas em Indaiatuba, Remoções com Segurança e Qualidade. Deslocamento
          Imediato!</h2>
          <h2>Ligue ou Chame no Whats!</h2>
      </div>
      <a href="tel:19992712115"><button class="b2"><img class="icon32" src='@/assets/phone-32.svg'/> Ligar agora</button></a>
      <a href="https://api.whatsapp.com/send?phone=5519992712115&amp;text=Olá%20Ney%20Guincho,%20Gostaria%20de%20solicitar%20um%20orçamento."><button class="b1"><img class="icon32" src="@/assets/whatsapp-32.svg"> WhatsApp 24 Horas</button></a>
      <div></div>
    </div>
    <div>
      <div class="g2">
        <div>
        <h2>Ney Guincho</h2>
        <div>
        <h2>Atendimento
              24 horas</h2>
            </div>
            <div>
              <img src="@/assets/24h.svg" alt="">
            </div>
      </div>

      </div>

    </div>
  </div>
</template>
<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang='scss'>
$a: absolute;
$f: fixed;
$fx: flex;
$n: none;
$bk: block;
$r: relative;
$l: left;
$cl: column;
$c1: #1B2854;
$c2: #fff;
$c3: #52c158;
$c4: #0c0c0c;
$c5: #717171;
$c6: #ffea52;
$c7: #f8f8f8;
$c8: #3d3d3c;
$c9: #001f2f;
$p: pointer;
$p_100: 100%;
$m1: 2rem;
$m2: 4.375rem;
$m3: 3.125rem;
$m4: 2.188rem;
$m5: 2rem;
$font_6: normal normal normal 19px/1.4em futura-lt-w01-light, futura-lt-w05-light, sans-serif;
@font-face {
  font-family: 'FuturaLTW01-LightOblique';
  src: url('../assets/fontes/9a7e574c3eda05d71d03345d3f9e268e.eot');
  src: url('../assets/fontes/9a7e574c3eda05d71d03345d3f9e268e.eot?#iefix') format('embedded-opentype'), url('../assets/fontes/9a7e574c3eda05d71d03345d3f9e268e.woff2') format('woff2'), url('../assets/fontes/9a7e574c3eda05d71d03345d3f9e268e.woff') format('woff'), url('../assets/fontes/9a7e574c3eda05d71d03345d3f9e268e.ttf') format('truetype'), url('../assets/fontes/9a7e574c3eda05d71d03345d3f9e268e.svg') format('svg');
}
@font-face {
  font-family: 'FuturaLTW01';
  src: url('../assets/fontes/W01/77d394fa0c1103c648f880b77cb4a32f.eot');
  src: url('../assets/fontes/9a7e574c3eda05d71d03345d3f9e268e.eot?#iefix') format('embedded-opentype'), url('../assets/fontes/W01/77d394fa0c1103c648f880b77cb4a32f.woff2') format('woff2'), url('../assets/fontes/W01/77d394fa0c1103c648f880b77cb4a32f.woff') format('woff'), url('../assets/fontes/W01/77d394fa0c1103c648f880b77cb4a32f.ttf') format('truetype'), url('../assets/fontes/W01/77d394fa0c1103c648f880b77cb4a32f.svg') format('svg');
}
*{
   margin:1rem 0!important;
}
h2{
  color:#fff
}
.g2{
  background: $c4;
}
/* img {
  width: 100%;
  height: 570px;
  object-fit: cover;
  object-position: 100% 100%;
} */
button.b1{
    background: #28a745;
  }
  button.b2{
    background: #0074cc;
  }

  button{
  color:#fff;
  border-radius: 50px;
  padding: .75rem 2.75rem!important;
  box-shadow: 0 .5rem 1.5rem rgba(0,0,0,.25);
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  @media only screen and (max-width: 600px) {
    padding: .1rem 1rem!important;
    min-width: 80%!important;
  }

  &:hover {
    -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
    box-shadow: 0 1rem 1.5rem rgba(0,0,0,.25);
  }
  }

  button, img{
    vertical-align: middle;
  }
.font_0 {
  font-weight: bold;
  font-family: 'FuturaLTW01-LightOblique';
  color: #fff;
}
.fbox {
  padding: 18px;
  /* background:#42b983;*/
}
/*BANNER*/
.banner {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  background: url('../assets/banner01.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position: right;
}
.banner .content {
  height: 100%;
  position: relative;
  background-color: transparent;
  // background-image:linear-gradient(260deg, var(--darkred) 44%, var(--darkred) 38%);
  /* transform:skew(20deg);*/
  position: absolute;
  /* left:-10%;*/
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // background:#42b983;
  h1 {
    max-width: 50%
  }
}
.banner .content section {
  padding: 0 10rem;
  transform: skew(-20deg);
  width: 100%;
  color: var(--white);
  z-index: 11111111;
}
@media (max-width:1120px) {
  .banner .content section {
    padding: 0 8rem
  }
}
@media (max-width:920px) {
  .banner {
    background-attachment: local
  }
  .banner .content {
    position: static;
    left: 0%;
    width: 100%;
    background-image: none
  }
  .banner .content section {
    padding: 2rem
  }
}
</style>
