<template>
  <section class="footer">
    <a href='https://api.whatsapp.com/send?phone=5519992712115&amp;text=Olá%20Ney%20Guincho,%20Gostaria%20de%20solicitar%20um%20orçamento%20de%20Guincho.'
      class='btn-whats conversion pulse'><span><img src='@/assets/whatsapp-32.svg'></span></a>
    <div>
      <div class="copy">
        <p>&copy;&nbsp;{{ year }},&nbsp;Ney Guincho.&nbsp;Todos&nbsp;os&nbsp;direitos&nbsp;reservados</p>
        <p>&nbsp;CNPJ:&nbsp;48.128.531/0001-90&nbsp;Indaiatuba&nbsp;-&nbsp;SP</p>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
$c3: #52c158;

.footer {
  margin-bottom: 20px;
  background: #333;
}

* {
  p {
    color: #fff
  }

  svg {
    color: red
  }
}

.copy {
  max-width: 100% !important;

  p {
    padding: 1rem !important;
    text-align: center !important;
    font-size: 98% !important;
    white-space: nowrap !important
  }
}

.btn-whats {
  background-color: $c3;
  color: white;
  text-decoration: none;
  border-radius: 100% !important;
  padding: 1.25rem !important;
  bottom: 40px;
  z-index: 99999;
  left: 93% !important;

  @media only screen and (max-width: 600px) {
    left: 75% !important
  }

  position: fixed;
  transition: all .6s;
  animation: pulse 2s infinite;
}

.btn-whats:hover {
  // background:rgba(56, 183, 102, 0.2);
  box-shadow: 0 0 2.5rem green;
}

.g2 {
  background: #fff;
}
</style>
<script>
export default {
  name: 'main',
  data: function () {
    return {
      year: ''
    }
  },
  mounted () {
    this.date = new Date()
    this.datetime = this.date.getFullYear() + '-' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '-' + ('0' + this.date.getDate()).slice(-2) + ' ' + ('0' + this.date.getHours()).slice(-2) + ':' + ('0' + this.date.getMinutes()).slice(-2) + ':' + ('0' + this.date.getSeconds()).slice(-2)
    document.querySelector('.main').addEventListener('scroll', this.handleScroll)
  },
  created () {
    this.year = new Date().getFullYear()
    this.h = new Date().getHours()
    this.m = new Date().getMinutes()
    this.nome = 'voce'
  }
}
</script>
